import * as bootstrap from 'bootstrap';

function chiCustom() {
  $('.scan-requested').on('click', function(event) {
    event.preventDefault();
    return false;
  });

  $('.request-scan').on('click', function() {
    event.preventDefault();
    const AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    const websiteId = $(this).attr('data-website-id');
    $.ajax({
      url: '/request_scan'+'?&authenticity_token='+AUTH_TOKEN,
      dataType: 'json',
      data: { website_id: websiteId },
      cache: false,
      type: 'POST'
    }).done(function (data) {
      location.reload();
    }).fail(function(jqXHR, textStatus, errorThrown) {
      // do nothing
    });
  });

  $('.setting-to-check, .setting-checked').on('change', function() {
    const AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    const websiteId = $(this).attr('data-website-id');
    const cookieSettingId = $(this).attr('data-cookie-setting-id');
    const profileValue = $(this).attr('data-profile-value');
    $.ajax({
      url: '/change_profile_setting'+'?&authenticity_token='+AUTH_TOKEN,
      dataType: 'json',
      data: { website_id: websiteId,
              profile_value: profileValue,
              cookie_setting_id: cookieSettingId
      },
      cache: false,
      type: 'POST'
    }).done(function (data) {
      location.reload();
    }).fail(function(jqXHR, textStatus, errorThrown) {
      // do nothing
    });
  });

  $('.replace-website-id').on('click', function() {
    website_id = $(this).attr('data-website-id');
    const send_message_id = '#send-message-' + website_id;
    const message_text_id = '#message-text-' + website_id;
    $(send_message_id).attr('data-website-id', website_id);
    $(send_message_id).attr('data-message-text', '');
    $(message_text_id).val('');
  });

  $('.message-text-area').on('input', function(event) {
    const send_message_id = '#send-message-' + $(this).attr('data-website-id');
    $(send_message_id).attr('data-message-text', $(this).val());
  });

  $('.change-website-product-owner').on('click', function(event) {
    event.preventDefault();
    const AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    const websiteId = $(this).attr('data-website-id');
    const messageText = $(this).attr('data-message-text');
    $.ajax({
      url: '/change_website_product_owner'+'?&authenticity_token='+AUTH_TOKEN,
      dataType: 'json',
      data: { website_id: websiteId, message_text: messageText },
      cache: false,
      type: 'POST'
    }).done(function (data) {
      $('#not-my-site-modal').hide();
      location.reload();
    }).fail(function(jqXHR, textStatus, errorThrown) {
      $('#not-my-site-modal').hide();
      $('#change-product-owner-error').show();
    });
  });

	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new bootstrap.Tooltip(tooltipTriggerEl)
	})
};

document.addEventListener('turbo:load', function() {
  chiCustom();
});
